.promotion-item {
    display: flex;
    touch-action: none;
    margin-top: 30px;
    flex-direction: column;

    label,
    input {
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
        color: #656565;
    }

    label {
        white-space: nowrap;
    }

    input:focus-visible,
    textarea:focus-visible {
        outline: none;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .promotion-input {
        margin-left: 5px;
        margin-right: 1px;
        text-align: center;
        width: 45px;
        border: 0.783063px solid #C5C5C5;
        border-radius: 10px;
        padding: 8px 12px;
    }

    .promotion-input-date-time {
        margin-left: 5px;
        margin-right: 5px;
        text-align: center;
        border: 0.783063px solid #C5C5C5;
        border-radius: 10px;
        padding: 8px 12px;
    }

    .promotion-input-amount {
        margin-right: 5px;
        text-align: center;
        width: 45px;
        border: 0.783063px solid #C5C5C5;
        border-radius: 10px;
        padding: 8px 12px;
    }

    .promotion-bold {
        font-weight: 700 !important;
    }

    .details-div-select-layout {
        border: 0.783063px solid #C5C5C5;
        border-radius: 10px;
        margin-right: 5px;
        margin-left: 5px;
        width: 200px;
    }

    .promotion-menu {
        width: 20px;
        height: 20px;
        margin-right: 20px;
        cursor: pointer;
    }

    .promotion-menu-hidden {
        width: 20px;
        height: 20px;
        margin-right: 20px;
        cursor: pointer;
        visibility: hidden;
    }

    .promotion-menu:focus {
        outline: none;
    }

    .promotion-add-icon {
        margin-left: 20px;
        cursor: pointer;
        filter: invert(56%) sepia(68%) saturate(1878%) hue-rotate(343deg) brightness(97%) contrast(103%);
    }

    .promotion-remove-icon {
        margin-left: 20px;
        cursor: pointer;
    }

    .promotion-item-content {
        display: flex;
        align-items: center;
    }

    .promotion-item-content:nth-child(2) {
        margin-top: 15px;
    }
}