.sidebar-content {
    background-color: #FEFEFE;
    width: 320px;
    height: 100vh;
    user-select: none;
    display: inline-block;

    .sidebar-option-container {
        height: 100%;
        display: flex;
        flex-direction: column;
        max-height: inherit;
    }

    .sidebar-logo {
        width: 203px;
        margin-top: 24px;
        margin-left: 35.45px;
        margin-bottom: 24px;
    }

    .sidebar-option {
        flex: 1;
        overflow: auto;
        max-height: inherit;
    }

    .nav-option-icon-container {
        width: 21px;
        height: 21px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .nav-option {
        margin-top: 12px;
        padding: 16px 35px 16px 35px;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        display: flex;
        align-items: center;
        gap: 18px;
        cursor: pointer;
    }

    .sidebar-option:last-child {
        padding-bottom: 50px;
    }

    .nav-icon-selected {
        background-color: #FC7921;
        color: white;

        .nav-option-icon path {
            fill: white;
        }

        .nav-option-arrow-icon path {
            stroke: white;
        }
    }

    .nav-icon-unselected {
        background-color: white;
        color: #1E1E1E;

        .nav-option-icon path {
            fill: black;
        }

        .nav-option-arrow-icon path {
            stroke: black;
        }
    }
}