.user-details {
    margin-top: 9.11px;
    display: flex;
    flex: 1;
    flex-direction: column;

    .header {
        display: flex;

        p:nth-child(1) {
            white-space: nowrap;
            font-style: normal;
            font-weight: 700;
            font-size: 28px;
            line-height: 38px;
            color: #1E1E1E;
        }

        p:nth-child(2) {
            margin-top: 10px;
            white-space: nowrap;
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 22px;
            color: #000000;
        }

        button {
            margin-left: auto;
            height: 38px;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            color: #FFFFFF;
            border: none;
            border-radius: 10px;
            background-color: #FC7921;
            padding: 0px 38px 0px 38px;
            white-space: nowrap;
        }

        .saveButton {
            background-color: #008926;
        }
    }

    .content {
        margin-top: 24.82px;
        margin-bottom: 32.65px;
        display: flex;
        height: 100%;
        width: 100%;

        .details-container {
            background-color: white;
            height: 100%;
            flex: 1;
            border-radius: 15px;
            display: flex;
            flex-direction: row;

            .more {
                // flex: 0.3;
                display: block;
                float: right;
                margin-top: 30px;
                margin-right: 25px;
                position: absolute;
                right: 0;

                .moreButtonContainer {
                    // float: right;
                    height: 18px;
                    margin-bottom: 10px;
                    padding-right: 13px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;

                    .last-active {
                        margin-top: -4px;
                        margin-right: 10px;
                        font-size: 13px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        color: #ADADAD;
                    }

                    .more-button {
                        width: 20px;
                        background: none;
                        border: none;
                    }
                }

                .moreAction {
                    width: 135px;
                    margin-left: auto;
                    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
                    background-color: white;

                    button {
                        background: none;
                        border: none;
                        width: 100%;
                        text-align: left;
                        height: 58px;
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 18px;
                        color: #313131;
                        padding: 10px 22px 10px;
                        align-items: center;
                        display: flex;

                        .more-icon {
                            margin-right: 5px;
                        }
                    }

                    .option-disable {
                        color: rgba(239, 70, 36, 1);
                    }

                    button:hover {
                        color: rgba(239, 70, 36, 1);
                        filter: invert(33%) sepia(58%) saturate(2216%) hue-rotate(346deg) brightness(95%) contrast(96%);
                    }
                }
            }

            .details {
                height: 100%;
                flex: 0.7;

                .details-title {
                    margin-left: 25.06px;
                    margin-right: 55.91px;
                    margin-top: 23.06px;
                    color: #000;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                }

                .details-form {
                    .details-layout {
                        display: flex;
                        margin-left: 25.06px;
                        margin-right: 25.06px;
                        margin-bottom: 31.36px;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 18px;
                        color: #656565;
                        overflow: hidden;

                        .links-container {
                            display: flex;
                            flex-direction: column;

                            .links {
                                color: #FC7921;
                                text-align: center;
                                overflow-wrap: anywhere !important;
                                margin-bottom: 10px;
                            }
                        }

                        .upload-files,
                        .new-files {
                            margin-top: 20px;
                        }

                        .upload-files:nth-child(1) {
                            margin-top: 0px;
                        }

                        .upload-image {
                            margin-top: 15.13px;
                            margin-bottom: 9.37px;
                            background: rgba(51, 108, 255, 0.05);
                            border: 0.542801px solid #336CFF;
                            border-radius: 2.71401px;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            padding: 5px 34.5px;
                            color: #2562FF;
                            font-style: normal;
                            font-weight: 600;
                            font-size: 12px;
                            line-height: 14px;
                            cursor: pointer;

                            label {
                                cursor: pointer;
                            }
                        }

                        .remove-image {
                            background: white;
                            border: none;
                            font-style: normal;
                            font-weight: 600;
                            font-size: 12px;
                            line-height: 14px;
                            color: #FC7921;
                            margin-bottom: 10.33px;
                        }

                        .details-layout-item:nth-child(1) {
                            flex: 0.5;
                        }

                        .details-layout-item:nth-child(2) {
                            flex: 1;
                            margin-left: 10px;
                            white-space: pre-line;
                        }

                        .details-layout-add-edit-label {
                            margin-top: 5px;
                        }

                        .details-layout-add-edit {
                            display: flex;
                            align-items: center;

                            .details-layout-add-icon {
                                width: 18px;
                                height: 18px;
                                margin-left: 20px;
                                cursor: pointer;
                                filter: invert(56%) sepia(68%) saturate(1878%) hue-rotate(343deg) brightness(97%) contrast(103%);
                            }

                            .details-layout-minus-icon {
                                width: 30px;
                                height: 30px;
                                margin-left: 15px;
                                cursor: pointer;
                                filter: invert(56%) sepia(68%) saturate(1878%) hue-rotate(343deg) brightness(97%) contrast(103%);
                            }
                        }

                        .details-layout-link {
                            display: flex;
                        }

                        .details-layout-link>p:nth-child(2) {
                            margin-left: 10px;
                            white-space: nowrap;
                            text-decoration: underline;
                            color: #FC7921;
                            cursor: pointer;
                        }
                    }

                    .document {
                        display: flex;
                        flex: 1;
                        flex-direction: column;
                        white-space: pre-wrap;
                        gap: 10px;

                        .document-ref {
                            color: rgba(239, 70, 36, 1);
                        }
                    }

                    .upload {
                        display: flex;
                        flex-direction: column;
                        border-radius: 15px;
                        border-color: #C5C5C5;
                        border-width: 2px;
                        border-style: dashed;
                        padding: 23px 35.5px 19.23px 35.5px;
                        align-items: center;
                        overflow: hidden;
                        flex: 1;
                        height: 201px;
                        justify-content: center;
                        align-items: center;

                        .upload-logo {
                            width: 120px;
                            height: 120px;
                        }

                        p {
                            font-style: normal;
                            font-weight: 400;
                            font-size: 10px;
                            line-height: 14px;
                            text-align: center;
                            color: #5D5D5F;
                            opacity: 0.6;
                        }
                    }

                    .details-div {
                        display: flex;
                        margin-left: 25.06px;
                        margin-right: 55.91px;
                        margin-bottom: 31.36px;
                        overflow: hidden;
                        align-items: center;

                        label,
                        input,
                        select {
                            font-style: normal;
                            font-weight: 400;
                            font-size: 13px;
                            line-height: 18px;
                            color: #656565;
                        }

                        ::placeholder {
                            color: #D4D4D4;
                            opacity: 1;
                        }

                        :-ms-input-placeholder {
                            color: #D4D4D4;
                        }

                        ::-ms-input-placeholder {
                            color: #D4D4D4;
                        }

                        label {
                            flex: 0.5;
                            white-space: nowrap;
                        }

                        .form-textarea {
                            align-items: normal;
                        }

                        .details-div-layout-input {
                            flex: 1;
                            border: 0.783063px solid #C5C5C5;
                            border-radius: 10px;
                            padding: 8px 12px;
                        }

                        input:focus-visible,
                        textarea:focus-visible {
                            outline: none;
                        }

                        .details-div-layout {
                            flex: 1;
                            display: flex;
                            flex-direction: column;

                            margin-left: 20px;

                            .details-div-icon {
                                flex: 1;
                                display: flex;

                                .details-div-radio {
                                    display: flex;
                                    align-items: center;
                                    cursor: pointer;
                                }

                                .details-div-radio2 {
                                    display: flex;
                                    align-items: center;
                                    margin-left: 55px;
                                    cursor: pointer;
                                }

                                .details-div-radio-label {
                                    margin-left: 10px;
                                }
                            }

                            .mutli-input-layout {
                                align-items: center;
                                margin-top: 10px;
                            }
                        }

                        .details-div-multi-layout {
                            border: 0.783063px solid #C5C5C5;
                            border-radius: 10px;
                        }
                    }

                    .dropdown-div {
                        overflow: visible !important;
                    }
                }

                .details-layout:nth-child(1) {
                    margin-top: 32.11px;
                }
            }
        }

        .details-supporting {
            background-color: white;
            flex: 0.51;
            overflow: hidden;
            margin-left: 25.49px;
            border-radius: 15px;
            padding-top: 23.06px;
            padding-left: 25.06px;
            padding-right: 15.9px;

            .details-supporting-container {
                display: flex;
                flex-direction: column;

                .details-supporting-label {
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    color: #000;
                }

                .details-layout-add-edit-label {
                    margin-top: 5px;
                }

                .details-layout-add-edit {
                    display: flex;
                    align-items: center;

                    .details-layout-add-icon {
                        width: 18px;
                        height: 18px;
                        margin-left: 20px;
                        cursor: pointer;
                        filter: invert(56%) sepia(68%) saturate(1878%) hue-rotate(343deg) brightness(97%) contrast(103%);
                    }

                    .details-layout-minus-icon {
                        width: 30px;
                        height: 30px;
                        margin-left: 15px;
                        cursor: pointer;
                        filter: invert(56%) sepia(68%) saturate(1878%) hue-rotate(343deg) brightness(97%) contrast(103%);
                    }
                }

                .details-supporting-form {
                    .details-layout {
                        display: flex;
                        margin-bottom: 31.36px;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 18px;
                        color: #656565;
                        overflow: hidden;

                        .details-layout-item:nth-child(1) {
                            flex: 0.5;
                        }

                        .details-layout-item:nth-child(2) {
                            flex: 1;
                            margin-left: 10px;
                            white-space: pre-line;
                        }
                    }
                }

                .details-layout:nth-child(1) {
                    margin-top: 32.11px;
                }
            }

            .details-supporting-container:not(:first-child) {
                margin-top: 16px;
            }
        }

        .details-disable {
            background-color: #dbdbdb;
        }
    }
}