.transaction-details {
    margin-top: 9.11px;
    display: flex;
    flex: 1;
    flex-direction: column;

    .header {
        display: flex;

        p:nth-child(1) {
            white-space: nowrap;
            font-style: normal;
            font-weight: 700;
            font-size: 28px;
            line-height: 38px;
            color: #1E1E1E;
        }

        p:nth-child(2) {
            margin-top: 10px;
            white-space: nowrap;
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 22px;
            color: #000000;
        }
    }

    .content {
        margin-top: 24.82px;
        margin-bottom: 32.65px;
        display: flex;
        height: 100%;
        width: 100%;

        .details {
            background-color: white;
            height: 100%;
            flex: 1;
            border-radius: 15px;

            .details-layout {
                display: flex;
                margin-left: 25.06px;
                margin-right: 25.06px;
                margin-bottom: 31.36px;
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 18px;
                color: #656565;
                overflow: hidden;

                p:nth-child(1) {
                    flex: 0.35;
                    white-space: nowrap;
                }

                p:nth-child(2) {
                    flex: 1;
                    margin-left: 10px;
                }

                a {
                    text-decoration: underline;
                    color: #FC7921;
                }
            }

            div:nth-child(1) {
                margin-top: 32.11px;
            }
        }
    }
}