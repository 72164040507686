.grid-container {
    display: flex;
    gap: 23.19px;
    width: 100%;

    .grid-item {
        flex: 1 1 0;
        background-color: white;
        border-radius: 15px;
        padding: 30px 32px 0px 23.36px;
        display: flex;

        .content {
            display: flex;
            flex-direction: column;

            p:nth-of-type(1) {
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 25px;
                color: #656565;
            }

            p:nth-of-type(2) {
                font-style: normal;
                font-weight: 700;
                font-size: 30px;
                line-height: 35px;
                color: #1E1E1E;
                margin-top: 9px;
            }

            p:nth-of-type(3) {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 19px;
                color: #656565;
                margin-top: 16.64px;
                margin-bottom: 23.36px;
            }
        }

        .icon {
            width: 75px;
            height: 75px;
            border-radius: 15px;
            margin-left: auto;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.grid-container-2 {
    display: flex;
    gap: 26.24px;
    width: 100%;
    margin-top: 20px;
    height: 100%;

    .grid-item-2 {
        flex: 1 1 0;
        background-color: white;
        border-radius: 15px;
        padding: 26px 34.28px 33.64px 25.73px;
        display: flex;
        margin-bottom: 27.36px;
        min-width: 0;
        width: 100%;

        .content {
            width: 100%;

            .header {
                display: flex;
                align-items: center;

                p {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 33px;
                    color: #1E1E1E;
                }

                .link {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 22px;
                    color: #007FC6;
                    margin-left: auto;
                }
            }
        }

        table {
            margin-top: 27px;
            width: 100%;
            text-align: left;

            th {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 19px;
                color: #1E1E1E;
                padding-bottom: 27px;
                padding-right: 10px;
            }

            .tr-1 {
                th:nth-child(1) {
                    width: 40%;
                }

                th:nth-child(2) {
                    width: 30%;
                }

                th:nth-child(3) {
                    width: 30%;
                }
            }

            .tr-2 {
                th:nth-child(1) {
                    width: 33%;
                }

                th:nth-child(2) {
                    width: 33%;
                }

                th:nth-child(3) {
                    width: 33%;
                }
            }

            tr {
                td {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 19px;
                    color: #1E1E1E;
                    padding-bottom: 30px;
                    padding-right: 10px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 110px;

                    a {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 19px;
                        color: #007FC6;
                        text-decoration: underline;
                        color: #FC7921;
                    }
                }
            }
        }

        .no-data {
            text-align: center;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            color: #1E1E1E;
        }
    }
}