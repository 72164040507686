.add-edit-notification-template {
    margin-top: 9.11px;
    display: flex;
    flex: 1;
    flex-direction: column;

    form {
        height: 100%;
        display: flex;
        flex-direction: column;

        .header {
            display: flex;

            p:nth-child(1) {
                white-space: nowrap;
                font-style: normal;
                font-weight: 700;
                font-size: 28px;
                line-height: 38px;
                color: #1E1E1E;
            }

            p:nth-child(2) {
                margin-top: 10px;
                white-space: nowrap;
                font-style: normal;
                font-weight: 400;
                font-size: 15px;
                line-height: 22px;
                color: #000000;
            }

            button {
                margin-left: auto;
                height: 38px;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 19px;
                color: #FFFFFF;
                border: none;
                border-radius: 10px;
                background-color: #008926;
                padding: 0px 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 11.5px;
                white-space: nowrap;
            }
        }

        .content {
            margin-top: 24.82px;
            margin-bottom: 32.65px;
            display: flex;
            height: 100%;
            width: 100%;

            .details-container {
                background-color: white;
                height: 100%;
                flex: 1;
                border-radius: 15px;
                display: flex;
                flex-direction: row;

                .more {
                    flex: 0.3;
                    display: block;
                    float: right;
                    margin-top: 30px;
                    margin-right: 25px;

                    .moreButtonContainer {
                        // float: right;
                        height: 18px;
                        margin-bottom: 10px;
                        padding-right: 13px;

                        .more-button {
                            width: 20px;
                            background: none;
                            border: none;
                            float: right;
                        }
                    }

                    .moreAction {
                        width: 135px;
                        margin-left: auto;
                        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

                        button {
                            background: none;
                            border: none;
                            width: 100%;
                            text-align: left;
                            height: 58px;
                            font-weight: 400;
                            font-size: 13px;
                            line-height: 18px;
                            color: #313131;
                            padding: 10px 22px 10px;
                            align-items: center;
                            display: flex;

                            .more-icon {
                                margin-right: 5px;
                            }
                        }

                        button:hover {
                            color: rgba(239, 70, 36, 1);
                            filter: invert(33%) sepia(58%) saturate(2216%) hue-rotate(346deg) brightness(95%) contrast(96%);
                        }
                    }
                }

                .details {
                    height: 100%;
                    flex: 1;

                    .details-title {
                        margin-left: 25.06px;
                        margin-right: 55.91px;
                        margin-top: 23.06px;
                        color: #000;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                    }

                    .details-form {
                        .details-div {
                            display: flex;
                            margin-left: 25.06px;
                            margin-right: 55.91px;
                            margin-bottom: 31.36px;
                            overflow: hidden;
                            align-items: center;

                            label,
                            input,
                            select {
                                font-style: normal;
                                font-weight: 400;
                                font-size: 13px;
                                line-height: 18px;
                                color: #656565;
                            }

                            ::placeholder {
                                color: #D4D4D4;
                                opacity: 1;
                            }

                            :-ms-input-placeholder {
                                color: #D4D4D4;
                            }

                            ::-ms-input-placeholder {
                                color: #D4D4D4;
                            }

                            label {
                                flex: 0.3;
                                white-space: nowrap;
                            }

                            .form-textarea {
                                align-items: normal;
                            }

                            .details-div-layout-input {
                                flex: 1;
                                border: 0.783063px solid #C5C5C5;
                                border-radius: 10px;
                                padding: 8px 12px;
                            }

                            input:focus-visible,
                            textarea:focus-visible {
                                outline: none;
                            }

                            .details-div-checkbox-label {
                                margin-left: 10px;
                            }

                            .details-div-layout {
                                flex: 1;
                                display: flex;
                                flex-direction: column;
                                margin-left: 10px;

                                .details-div-icon {
                                    flex: 1;
                                    display: flex;

                                    .details-div-radio {
                                        display: flex;
                                        align-items: center;
                                        cursor: pointer;
                                    }

                                    .details-div-radio2 {
                                        display: flex;
                                        align-items: center;
                                        margin-left: 55px;
                                        cursor: pointer;
                                    }

                                    .details-div-radio-label {
                                        margin-left: 10px;
                                    }
                                }

                                .mutli-input-layout {
                                    align-items: center;
                                    margin-top: 10px;
                                }

                                .details-preview {
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 13px;
                                    line-height: 18px;
                                    color: #656565;
                                }

                                .details-preview>p>a {
                                    color: #FC7921;
                                }
                            }

                            .details-div-multi-layout {
                                border: 0.783063px solid #C5C5C5;
                                border-radius: 10px;
                            }

                            input::-webkit-outer-spin-button,
                            input::-webkit-inner-spin-button {
                                -webkit-appearance: none;
                                margin: 0;
                            }

                            .details-div-layout-textarea {
                                flex: 1;
                                border: 0.783063px solid #C5C5C5;
                                border-radius: 10px;
                            }

                            .notification-toolbar {
                                padding: 1rem;
                                border: 0.783063px solid #C5C5C5;
                            }

                            .notification-editor {
                                height: 200px;
                                padding-left: 1em;
                                padding-right: 1em;
                                border: 0.783063px solid #C5C5C5;
                                font-weight: 400;
                                font-size: 13px;
                                color: #656565;

                                .public-DraftEditor-content {
                                    overflow-wrap: anywhere !important;
                                    border: solid thin white;
                                }

                                a {
                                    text-decoration: underline;
                                    color: #FC7921;
                                }
                            }

                            .details-layout-textarea {
                                width: calc(100% - 24px);
                                margin: 8px 12px;
                                resize: none;
                                font-style: normal;
                                font-weight: 400;
                                font-size: 13px;
                                line-height: 18px;
                                color: #656565;
                                border: none;
                            }

                            .range-div {
                                display: flex;
                                align-items: center;
                                gap: 15px;
                                flex: 1;
                            }

                            .required:before {
                                content: "* ";
                                color: red;
                            }
                        }

                        .details-add-icon {
                            flex: 0.05;
                            cursor: pointer;
                            filter: invert(56%) sepia(68%) saturate(1878%) hue-rotate(343deg) brightness(97%) contrast(103%);
                        }

                        .details-remove-icon {
                            flex: 0.05;
                            cursor: pointer;
                        }

                        .multi-div {
                            align-items: start;

                            label {
                                margin-top: 7px;
                            }

                            .details-add-icon {
                                margin-top: 7px;
                            }
                        }

                        .dropdown-div {
                            overflow: visible;
                        }

                        .form-textarea {
                            align-items: normal;
                        }

                        .details-div:nth-child(1) {
                            margin-top: 25.06px;
                        }

                        .details-div {
                            margin-top: 20px;
                        }

                        .details-image {
                            background-color: white;
                            height: 100%;
                            overflow: hidden;
                            margin-left: 25.49px;
                            margin-right: 55.91px;
                            margin-bottom: 31.36px;

                            .logo {
                                display: flex;
                                font-style: normal;
                                font-weight: 400;
                                font-size: 16px;
                                line-height: 22px;
                                color: #656565;

                                .logo-label {
                                    flex: 0.3 1;
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 13px;
                                    line-height: 18px;
                                    color: #656565;
                                }

                                .upload {
                                    display: flex;
                                    flex-direction: column;
                                    border-radius: 15px;
                                    border-color: #C5C5C5;
                                    border-width: 2px;
                                    border-style: dashed;
                                    padding: 23px 0px 19.23px 0px;
                                    align-items: center;
                                    overflow: hidden;
                                    margin-left: 10px;
                                    flex: 1 1;

                                    .upload-logo {
                                        width: 160px;
                                        height: 160px;
                                    }

                                    p {
                                        font-style: normal;
                                        font-weight: 400;
                                        font-size: 10px;
                                        line-height: 14px;
                                        text-align: center;
                                        color: #5D5D5F;
                                        opacity: 0.6;
                                    }
                                }
                            }

                            .profile-banner {
                                display: flex;
                                flex-direction: column;
                                margin-left: 30px;
                                margin-right: 30px;
                                margin-top: 40px;
                                margin-bottom: 20px;
                                font-style: normal;
                                font-weight: 400;
                                font-size: 16px;
                                line-height: 22px;
                                color: #656565;

                                .upload {
                                    display: flex;
                                    flex-direction: column;
                                    border-radius: 15px;
                                    border-color: #C5C5C5;
                                    border-width: 2px;
                                    border-style: dashed;
                                    margin-top: 23px;
                                    padding: 23px 35.5px 19.23px 35.5px;
                                    align-items: center;
                                    margin-left: 27px;
                                    margin-right: 27px;
                                    overflow: hidden;

                                    .upload-profile-banner {
                                        width: 119.68px;
                                        height: 60px;
                                    }

                                    p {
                                        font-style: normal;
                                        font-weight: 400;
                                        font-size: 10px;
                                        line-height: 14px;
                                        text-align: center;
                                        color: #5D5D5F;
                                        opacity: 0.6;
                                    }
                                }
                            }

                            .upload-image {
                                margin-top: 15.13px;
                                margin-bottom: 9.37px;
                                background: rgba(51, 108, 255, 0.05);
                                border: 0.542801px solid #336CFF;
                                border-radius: 2.71401px;
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                align-items: center;
                                padding: 5px 34.5px;
                                color: #2562FF;
                                font-style: normal;
                                font-weight: 600;
                                font-size: 12px;
                                line-height: 14px;
                                cursor: pointer;

                                label {
                                    cursor: pointer;
                                }
                            }

                            .remove-image {
                                background: white;
                                border: none;
                                font-style: normal;
                                font-weight: 600;
                                font-size: 12px;
                                line-height: 14px;
                                color: #FC7921;
                                margin-bottom: 10.33px;
                            }
                        }
                    }
                }
            }
        }
    }
}