* {
  font-family: 'Open Sans', sans-serif;
  margin: 0;
  position: relative;
}

button {
  cursor: pointer;
}

.app {
  display: flex;
  background-color: #F5F5F5;
  min-height: 100vh;
  min-width: 100%;
  position: absolute;
}

.navClose {
  display: none;
}

.app-main {
  display: flex;
  height: 100%;
  width: 100%;
}

.app-main-content {
  display: flex;
  flex: 1;
  height: 100vh;
  overflow: auto;
  flex-direction: column;
  padding-left: 30.45px;
  padding-right: 29.55px;
}

.app-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: url("./assets/ic_date.svg") no-repeat;
}

/* @media screen and (max-width:767px) {
  .sidebar {
    position: absolute;
  }
} */