.agent_banners {
    margin-top: 9.11px;
    display: flex;
    flex: 1;
    flex-direction: column;

    .header {
        display: flex;

        p:nth-child(1) {
            white-space: nowrap;
            font-style: normal;
            font-weight: 700;
            font-size: 28px;
            line-height: 38px;
            color: #1E1E1E;
        }

        p:nth-child(2) {
            margin-top: 10px;
            white-space: nowrap;
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 22px;
            color: #000000;
        }

        button {
            margin-left: auto;
            height: 38px;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            color: #FFFFFF;
            border: none;
            border-radius: 10px;
            background-color: #FC7921;
            padding: 0px 38px 0px 38px;
            white-space: nowrap;
        }
    }

    .content {
        margin-top: 24.82px;
        margin-bottom: 32.65px;
        display: flex;
        height: 100%;
        width: 100%;

        .details {
            background-color: white;
            flex: 1;
            border-radius: 15px;
            padding-left: 25.06px;
            padding-right: 55.91px;
            padding-top: 23.06px;

            .details-layout-container {
                display: flex;
                margin-top: 10px;
                margin-bottom: 40px;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;
                color: #656565;
                overflow: hidden;
                flex-direction: column;

                .details-layout-content {
                    display: flex;
                    gap: 10px;
                    margin-top: 15px;

                    .details-layout {
                        display: flex;
                        flex: 1;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 18px;
                        color: #656565;
                        overflow: hidden;

                        .details-layout-item:nth-child(1) {
                            flex: 0.10;
                        }

                        .details-layout-item:nth-child(2) {
                            flex: 1;
                            white-space: pre-line;
                        }

                        .details-layout-item>p>a {
                            text-decoration: underline;
                            color: #FC7921;
                        }

                        .details-layout-refresh {
                            display: flex;
                            align-items: center;

                            .details-layout-refresh-icon {
                                width: 18px;
                                height: 18px;
                                margin-left: 15px;
                                cursor: pointer;

                                path {
                                    fill: #FC7921;
                                }
                            }
                        }
                    }

                    .upload {
                        display: flex;
                        flex-direction: column;
                        border-radius: 15px;
                        border-color: #C5C5C5;
                        border-width: 2px;
                        border-style: dashed;
                        padding: 23px 35.5px 19.23px 35.5px;
                        align-items: center;
                        overflow: hidden;
                        flex: 1;
                        height: 120px;
                        width: 220px;
                        justify-content: center;
                        align-items: center;

                        .upload-logo {
                            height: 100px;
                            width: 100px;
                        }

                        p {
                            font-style: normal;
                            font-weight: 400;
                            font-size: 10px;
                            line-height: 14px;
                            text-align: center;
                            color: #5D5D5F;
                            opacity: 0.6;
                        }
                    }
                }

                .details-layout-content>p:nth-child(1) {
                    visibility: hidden;
                    font-weight: 700;
                }

                .details-layout-content:nth-child(1) {
                    margin-top: 0px;

                    p:nth-child(1) {
                        visibility: visible;
                    }
                }
            }

            label {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;
                color: #656565;
            }

            .required:before {
                content: "* ";
                color: red;
            }
        }
    }
}