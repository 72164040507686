.session-details {
    margin-top: 9.11px;
    display: flex;
    flex: 1;
    flex-direction: column;

    .header {
        display: flex;

        p:nth-child(1) {
            white-space: nowrap;
            font-style: normal;
            font-weight: 700;
            font-size: 28px;
            line-height: 38px;
            color: #1E1E1E;
        }

        p:nth-child(2) {
            margin-top: 10px;
            white-space: nowrap;
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 22px;
            color: #000000;
        }

        button {
            height: 38px;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            color: #FFFFFF;
            border: none;
            border-radius: 10px;
            background-color: #FC7921;
            padding: 0px 38px 0px 38px;
            white-space: nowrap;
        }

        .second-button {
            margin-left: auto;
            margin-right: 20px;
        }

        .first-button {
            margin-left: auto;
        }

        .red-button {
            background-color: red;
        }
    }

    .content {
        margin-top: 24.82px;
        margin-bottom: 32.65px;
        display: flex;
        height: 100%;
        width: 100%;
        flex-direction: column;

        .details {
            background-color: white;
            height: 100%;
            flex: 1;
            border-radius: 15px;

            .details-title {
                margin-left: 25.06px;
                margin-right: 55.91px;
                margin-top: 23.06px;
                margin-bottom: 32.11px;
                color: #000;
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }

            .details-breakdown>.details-title {
                margin-top: 51.36px;
            }

            .details-layout {
                display: flex;
                margin-left: 25.06px;
                margin-right: 25.06px;
                margin-bottom: 31.36px;
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 18px;
                color: #656565;
                overflow: hidden;

                .details-layout-item:nth-child(1) {
                    flex: 0.35;
                }

                .details-layout-item:nth-child(2) {
                    flex: 1;
                    white-space: pre-line;
                }

                .details-layout-item>p>a {
                    text-decoration: underline;
                    color: #FC7921;
                }
            }

            .details-layout:nth-child(1) {
                margin-top: 32.11px;
            }

            .upload {

                .image-grid-container {
                    display: grid;
                    grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
                    gap: 16px;

                    .image-grid-item {
                        box-sizing: border-box;
                    }
                }

                .upload-logo {
                    height: 130px;
                    width: 130px;
                    object-fit: cover;
                    object-position: center;
                }

                p {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 10px;
                    line-height: 14px;
                    text-align: center;
                    color: #5D5D5F;
                    opacity: 0.6;
                }
            }
        }

        .details:nth-child(2) {
            margin-top: 25.49px;
        }
    }
}