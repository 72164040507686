.search-bar {
    flex: 1;

    .search-bar-container {
        margin-left: 55.5px;
        // margin-right: 55.5px;
        background-color: white;
        border-color: #F3F3F3;
        border-style: solid;
        border-width: 1px;
        border-radius: 10px;
        gap: 16.21px;
        padding: 8.8px 21.84px 8.8px 21.84px;
        display: flex;
        align-items: center;

        .search-bar-input {
            border: 0;
            flex: 1;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            color: black;
        }

        .search-bar-input::placeholder {
            color: #C5C5C5;
            opacity: 1;
        }

        .search-bar-input:-ms-input-placeholder {
            color: #C5C5C5;
        }

        .search-bar-input::-ms-input-placeholder {
            color: #C5C5C5;
        }

        .search-bar-input:focus {
            outline: none;
        }

        .search-bar-filter {
            margin-left: auto;
            gap: 24.22px;
            display: flex;
            flex-direction: row;
            align-items: center;

            .details-div {
                display: flex;
                margin-left: 0px;
                margin-right: 0px;
                overflow: hidden;
                align-items: center;

                label,
                input,
                select {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 18px;
                    color: #656565;
                }

                ::placeholder {
                    color: #D4D4D4;
                    opacity: 1;
                }

                :-ms-input-placeholder {
                    color: #D4D4D4;
                }

                ::-ms-input-placeholder {
                    color: #D4D4D4;
                }

                label {
                    flex: 0.5;
                    white-space: nowrap;
                }

                .form-textarea {
                    align-items: normal;
                }

                .details-div-layout-input {
                    flex: 1;
                    padding: 8px 12px;
                }

                input:focus-visible,
                textarea:focus-visible {
                    outline: none;
                }

                .details-div-layout {
                    flex: 1;
                    display: flex;
                    flex-direction: column;

                    margin-left: 20px;

                    .details-div-icon {
                        flex: 1;
                        display: flex;

                        .details-div-radio {
                            display: flex;
                            align-items: center;
                            cursor: pointer;
                        }

                        .details-div-radio2 {
                            display: flex;
                            align-items: center;
                            margin-left: 55px;
                            cursor: pointer;
                        }

                        .details-div-radio-label {
                            margin-left: 10px;
                        }
                    }

                    .mutli-input-layout {
                        align-items: center;
                        margin-top: 10px;
                    }
                }

                .details-div-multi-layout {
                    border-radius: 10px;
                    border-color: #F3F3F3;
                    border-style: solid;
                    border-width: 1px;
                }
            }

            .dropdown-div {
                overflow: visible !important;
            }
        }
    }
}