.redemption-history {
    margin-top: 9.11px;

    .header {
        display: flex;
        align-items: center;

        p {
            white-space: nowrap;
            font-style: normal;
            font-weight: 700;
            font-size: 28px;
            line-height: 38px;
            color: #1E1E1E;
        }
    }

    .table-container {
        margin-top: 20.17px;
    }
}