.navbar {
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 10px 32px 10px 47.27px;
    background-color: white;
    border-radius: 15px;
    align-items: center;

    .navbar-profile {
        margin-left: auto;
        // margin-right: 21.59px;
        align-items: flex-end;
        display: flex;
        flex-direction: column;
        min-height: 61px;
        justify-content: center;

        .name {
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            color: #1E1E1E;
            line-height: 22px;
        }

        .role {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            color: #656565;
            line-height: 19px;
        }
    }

    .navbar-menu-icon {
        cursor: pointer;
    }

    .navbar-profile-icon {
        width: 61px;
        height: 61px;
        background-color: #EFEFEF;
        border-radius: 30.5px;
        justify-content: center;
        align-items: center;
        display: flex;

        .nav-avatar {
            width: 61px;
            height: 61px;
            border-radius: 30.5px;
            overflow: hidden;
        }
    }
}