.admins {
    margin-top: 9.11px;

    .header {
        display: flex;
        align-items: center;

        p {
            white-space: nowrap;
            font-style: normal;
            font-weight: 700;
            font-size: 28px;
            line-height: 38px;
            color: #1E1E1E;
        }

        .add-button {
            margin-left: 50px;
            display: flex;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            color: #FFFFFF;
            justify-content: center;
            align-items: center;
            border: none;
            border-radius: 10px;
            background-color: #FC7921;
            padding: 11px 31px 11px 31px;
            gap: 12px;
            white-space: nowrap;

            span:nth-of-type(1) {
                width: 15px;
                height: 15px;
            }
        }

        .export-button {
            margin-left: 10px;
            display: flex;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            color: #FFFFFF;
            justify-content: center;
            align-items: center;
            border: none;
            border-radius: 10px;
            background-color: #FC7921;
            padding: 11px 31px 11px 31px;
            gap: 12px;
            white-space: nowrap;
        }

        .details-div {
            display: flex;
            margin-left: 25.06px;
            margin-right: 55.91px;
            overflow: hidden;
            align-items: center;

            label,
            input,
            select {
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 18px;
                color: #656565;
            }

            ::placeholder {
                color: #D4D4D4;
                opacity: 1;
            }

            :-ms-input-placeholder {
                color: #D4D4D4;
            }

            ::-ms-input-placeholder {
                color: #D4D4D4;
            }

            label {
                flex: 0.5;
                white-space: nowrap;
            }

            .form-textarea {
                align-items: normal;
            }

            .details-div-layout-input {
                flex: 1;
                border: 0.783063px solid #C5C5C5;
                border-radius: 10px;
                padding: 8px 12px;
            }

            input:focus-visible,
            textarea:focus-visible {
                outline: none;
            }

            .details-div-layout {
                flex: 1;
                display: flex;
                flex-direction: column;

                margin-left: 20px;

                .details-div-icon {
                    flex: 1;
                    display: flex;

                    .details-div-radio {
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                    }

                    .details-div-radio2 {
                        display: flex;
                        align-items: center;
                        margin-left: 55px;
                        cursor: pointer;
                    }

                    .details-div-radio-label {
                        margin-left: 10px;
                    }
                }

                .mutli-input-layout {
                    align-items: center;
                    margin-top: 10px;
                }
            }

            .details-div-multi-layout {
                border: 0.783063px solid #C5C5C5;
                border-radius: 10px;
            }
        }

        .dropdown-div {
            overflow: visible !important;
        }
    }

    .table-container {
        margin-top: 20.17px;
    }
}