.sessions {
    margin-top: 9.11px;

    .react-calendar__tile--now {
        background: #FC792150;
    }

    .react-calendar__tile--now:enabled:hover,
    .react-calendar__tile--now:enabled:focus {
        background: #FC792150;
    }

    .react-calendar__tile--active {
        background: #FC7921;
        color: white;
    }

    .react-calendar__tile--active:enabled:hover,
    .react-calendar__tile--active:enabled:focus {
        background: #FC7921;
    }

    .header {
        display: flex;
        align-items: center;

        p {
            white-space: nowrap;
            font-style: normal;
            font-weight: 700;
            font-size: 28px;
            line-height: 38px;
            color: #1E1E1E;
        }

        button {
            margin-left: 70px;
            display: flex;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            color: #FFFFFF;
            justify-content: center;
            align-items: center;
            border: none;
            border-radius: 10px;
            background-color: #FC7921;
            padding: 11px 31px 11px 31px;
            gap: 12px;
            white-space: nowrap;

            span:nth-of-type(1) {
                width: 15px;
                height: 15px;
            }
        }
    }

    .sessions-calendar {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
    }

    .search-bar {
        .search-bar-container {
            margin-left: 0;
            margin-top: 30px;
        }
    }

    .table-container {
        margin-top: 20.17px;
    }
}