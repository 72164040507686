.custom-table {
    border-spacing: 0 10px;
    border-collapse: separate;
    width: 100%;

    thead {
        th {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            color: #1E1E1E;
            padding-top: 8px;
            padding-bottom: 8px;
            padding-left: 4px;
            padding-right: 4px;
            background-color: white;
            white-space: nowrap;
            overflow: hidden;

            .custom-table-head {
                display: flex;
                gap: 4px;
                align-items: center;

                button {
                    border: none;
                    background-color: transparent;
                }
            }
        }

        th:first-child {
            padding-left: 21px;
            border-radius: 10px 0px 0px 10px;
        }

        th:last-child {
            padding-right: 21px;
            border-radius: 0px 10px 10px 0px;
        }
    }

    tbody {
        tr {
            cursor: pointer;
        }

        .tr-disable-pointer {
            cursor: default;
        }

        td {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            color: #1E1E1E;
            background-color: white;
            padding-top: 24px;
            padding-bottom: 24px;
            padding-left: 4px;
            padding-right: 4px;

            .custom-table-body {
                // display: block;
                // align-items: center;
                // white-space: nowrap;
                white-space: pre-line;
                text-overflow: ellipsis;
                overflow: hidden;
                // max-width: 165px;
                word-break: break-word;
                display: block;
                -webkit-box-orient: vertical;
                // width: 100%;

                .table-link {
                    color: #FC7921;
                }
            }

            .td-clickable {
                text-decoration: underline;
                color: #FC7921;
                cursor: pointer;
            }

            .active {
                color: #008926;
            }

            .inactive {
                color: #FC7921
            }

            .custom-table-body-icon {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 4px;

                button {
                    background: none;
                    border: none;
                }
            }

            .custom-button-container {
                display: flex;
                flex-direction: column;
                gap: 10px;
                align-items: center;

                .custom-button-enable {
                    display: flex;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 19px;
                    color: #FFFFFF;
                    justify-content: center;
                    align-items: center;
                    border: none;
                    border-radius: 10px;
                    background-color: #FC7921;
                    padding: 11px 31px 11px 31px;
                    gap: 12px;
                    white-space: nowrap;
                }

                .custom-button-enable-red {
                    display: flex;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 19px;
                    color: #FFFFFF;
                    justify-content: center;
                    align-items: center;
                    border: none;
                    border-radius: 10px;
                    background-color: red;
                    padding: 11px 31px 11px 31px;
                    gap: 12px;
                    white-space: nowrap;
                }

                .custom-button-disable {
                    display: flex;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 19px;
                    color: #656565;
                    justify-content: center;
                    align-items: center;
                    border: none;
                    border-radius: 10px;
                    background-color: #dbdbdb;
                    padding: 11px 31px 11px 31px;
                    gap: 12px;
                    white-space: nowrap;
                }
            }

            .details-div {
                display: flex;
                // margin-left: 25.06px;
                // margin-right: 55.91px;
                overflow: hidden;
                align-items: center;

                label,
                input,
                select {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 18px;
                    color: #656565;
                }

                ::placeholder {
                    color: #D4D4D4;
                    opacity: 1;
                }

                :-ms-input-placeholder {
                    color: #D4D4D4;
                }

                ::-ms-input-placeholder {
                    color: #D4D4D4;
                }

                label {
                    flex: 0.5;
                    white-space: nowrap;
                }

                .form-textarea {
                    align-items: normal;
                }

                .details-div-layout-input {
                    flex: 1;
                    border: 0.783063px solid #C5C5C5;
                    border-radius: 10px;
                    padding: 8px 12px;
                }

                input:focus-visible,
                textarea:focus-visible {
                    outline: none;
                }

                .details-div-layout {
                    flex: 1;
                    display: flex;
                    flex-direction: column;

                    .details-div-icon {
                        flex: 1;
                        display: flex;

                        .details-div-radio {
                            display: flex;
                            align-items: center;
                            cursor: pointer;
                        }

                        .details-div-radio2 {
                            display: flex;
                            align-items: center;
                            margin-left: 55px;
                            cursor: pointer;
                        }

                        .details-div-radio-label {
                            margin-left: 10px;
                        }
                    }

                    .mutli-input-layout {
                        align-items: center;
                        margin-top: 10px;
                    }
                }

                .details-div-multi-layout {
                    border: 0.783063px solid #C5C5C5;
                    border-radius: 10px;
                }
            }

            .dropdown-div {
                overflow: visible !important;
            }
        }

        .tr-disable {
            td {
                background-color: #dbdbdb;
            }
        }

        td:first-child {
            padding-left: 21px;
            border-radius: 10px 0px 0px 10px;
        }

        td:last-child {
            padding-right: 21px;
            border-radius: 0px 10px 10px 0px;
        }
    }
}

.custom-table-footer {
    margin-top: 28px;
    margin-bottom: 22px;
    display: flex;
    align-items: center;

    p {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: #9C9C9C;
    }

    .custom-table-page {
        margin-left: auto;
        display: flex;
        background: white;
        border-radius: 10px;
        align-items: center;

        button {
            background-color: white;
            border: none;
            border-radius: 10px;
            padding: 11px 20px 11px 20px;
        }

        .selected {
            background-color: #FC7921;
            color: white;
        }
    }
}