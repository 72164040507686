.faqs-item {
    display: flex;
    touch-action: none;
    margin-top: 10px;
    margin-bottom: 40px;
    flex-direction: column;

    label,
    input,
    textarea {
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
        color: #656565;
    }

    input:focus-visible,
    textarea:focus-visible {
        outline: none;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .faqs-input-container {
        display: flex;
        flex: 1;
        align-items: center;

        .icon-hidden {
            visibility: hidden;
        }
    }

    .faqs-textarea {
        margin-top: 15px;
        margin-left: 5px;
        margin-right: 1px;
        flex: 0.6;
        border: 0.783063px solid #C5C5C5;
        border-radius: 10px;
        padding: 8px 12px;
        resize: none;
        min-width: 0;
    }

    .faqs-input {
        margin-left: 5px;
        margin-right: 1px;
        flex: 0.6;
        border: 0.783063px solid #C5C5C5;
        border-radius: 10px;
        padding: 8px 12px;
        min-width: 0;
    }

    .faqs-menu {
        width: 20px;
        height: 20px;
        margin-right: 20px;
        cursor: pointer;
    }

    .faqs-menu:focus {
        outline: none;
    }

    .faqs-add-icon {
        margin-left: 20px;
        cursor: pointer;
        filter: invert(56%) sepia(68%) saturate(1878%) hue-rotate(343deg) brightness(97%) contrast(103%);
    }

    .faqs-remove-icon {
        margin-left: 20px;
        cursor: pointer;
    }
}