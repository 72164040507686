.agent-banners-item {
    display: flex;
    touch-action: none;
    margin-top: 10px;
    margin-bottom: 40px;
    flex-direction: column;

    .agent-banners-input-container {
        display: flex;
        flex: 1;
        align-items: center;

        .icon-hidden {
            visibility: hidden;
        }
    }

    .agent-banners-textarea {
        border: 0.783063px solid #C5C5C5;
        border-radius: 10px;
        flex: 1;
        padding: 8px 12px;
        resize: none;
        min-width: 0;
    }

    .details-div-layout {
        display: flex;
        flex: 0.6;
        margin-top: 15px;
        margin-left: 5px;
        margin-right: 1px;
        flex-direction: column;

        label,
        input,
        textarea {
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 18px;
            color: #656565;
        }

        input:focus-visible,
        textarea:focus-visible {
            outline: none;
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }

    .details-div-layout-1 {
        display: flex;
        flex: 0.6;
        margin-left: 5px;
        margin-right: 1px;
        flex-direction: column;

        label,
        input,
        textarea {
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 18px;
            color: #656565;
        }

        input:focus-visible,
        textarea:focus-visible {
            outline: none;
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }

    .details-image {
        flex: 0.6;
        margin-top: 15px;
        margin-left: 5px;
        margin-right: 1px;
        background-color: white;
        height: 100%;
        overflow: hidden;

        .logo {
            display: flex;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            color: #656565;

            .logo-label {
                flex: 0.3 1;
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 18px;
                color: #656565;
            }

            .upload {
                display: flex;
                flex-direction: column;
                border-radius: 15px;
                border-color: #C5C5C5;
                border-width: 2px;
                border-style: dashed;
                padding: 23px 0px 19.23px 0px;
                align-items: center;
                overflow: hidden;
                flex: 1 1;

                .upload-logo {
                    width: 100px;
                    height: 100px;
                }

                p {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 10px;
                    line-height: 14px;
                    text-align: center;
                    color: #5D5D5F;
                    opacity: 0.6;
                }
            }
        }

        .upload-image {
            margin-top: 15.13px;
            margin-bottom: 9.37px;
            background: rgba(51, 108, 255, 0.05);
            border: 0.542801px solid #336CFF;
            border-radius: 2.71401px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 5px 34.5px;
            color: #2562FF;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 14px;
            cursor: pointer;

            label {
                cursor: pointer;
            }
        }
    }

    .details-div-multi-layout {
        border: 0.783063px solid #C5C5C5;
        border-radius: 10px;
    }

    .agent-banners-input {
        border: 0.783063px solid #C5C5C5;
        border-radius: 10px;
        padding: 8px 12px;
        min-width: 0;
    }

    .agent-banners-menu {
        width: 20px;
        height: 20px;
        margin-right: 20px;
        cursor: pointer;
    }

    .agent-banners-menu:focus {
        outline: none;
    }

    .agent-banners-add-icon {
        margin-left: 20px;
        cursor: pointer;
        filter: invert(56%) sepia(68%) saturate(1878%) hue-rotate(343deg) brightness(97%) contrast(103%);
    }

    .agent-banners-remove-icon {
        margin-left: 20px;
        cursor: pointer;
    }
}