.confirm-dialog {
    background-color: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;

    .backgroundButton {
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: transparent;
        cursor: auto;
        border: none;
    }

    .container {
        margin-top: 40px;
        background-color: white;
        width: auto;
        height: auto;
        margin: 0 auto;
        padding: 32px;
        position: relative;
        max-width: 40%;
        border-radius: 20px;

        .titleContainer {
            display: flex;
            gap: 27px;
            align-items: center;
            align-content: center;

            svg {
                min-width: 99px;
                min-height: 99px;
                max-width: 100px;
                max-height: 100px;
            }

            .titleWrapper {
                .title {
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 25px;
                    color: #656565;
                }

                .subtitle {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 19px;
                    color: #1E1E1E;
                    margin-top: 5px;
                }

                .details-layout {
                    display: flex;
                    margin-top: 20px;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 18px;
                    color: #656565;
                    overflow: hidden;

                    .details-layout-item:nth-child(2) {
                        margin-left: auto;
                        white-space: pre-line;
                    }

                    .details-layout-item>p>a {
                        text-decoration: underline;
                        color: #FC7921;
                    }
                }
            }

            .details-mutli-line {
                flex: 1;
            }
        }

        .buttonContainer {
            display: flex;
            gap: 10px;
            justify-content: space-between;
            margin-top: 40px;

            button {
                position: relative;
                flex-wrap: wrap;
                min-width: 130px;
                height: 40px;
                background: none;
                border-radius: 10px;
                align-items: center;
                align-content: center;
                border: none;
                display: flex;
                gap: 10px;
                justify-content: center;
                color: #1E1E1E;
            }

            button:nth-child(1) {
                justify-content: left;
            }

            button:nth-child(2) {
                path {
                    stroke: white !IMPORTANT;
                }
            }
        }
    }
}