.edit-promotions {
    margin-top: 9.11px;
    display: flex;
    flex: 1;
    flex-direction: column;

    form {
        height: 100%;
        display: flex;
        flex-direction: column;

        .header {
            display: flex;

            p:nth-child(1) {
                white-space: nowrap;
                font-style: normal;
                font-weight: 700;
                font-size: 28px;
                line-height: 38px;
                color: #1E1E1E;
            }

            p:nth-child(2) {
                margin-top: 10px;
                white-space: nowrap;
                font-style: normal;
                font-weight: 400;
                font-size: 15px;
                line-height: 22px;
                color: #000000;
            }

            button {
                margin-left: auto;
                height: 38px;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 19px;
                color: #FFFFFF;
                border: none;
                border-radius: 10px;
                background-color: #008926;
                padding: 0px 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 11.5px;
                white-space: nowrap;
            }
        }

        .content {
            margin-top: 24.82px;
            margin-bottom: 32.65px;
            display: flex;
            height: 100%;
            width: 100%;

            .details {
                background-color: white;
                height: 100%;
                flex: 1;
                border-radius: 15px;

                .details-form {
                    padding-left: 25.06px;
                    padding-right: 55.91px;
                    padding-top: 23.06px;

                    label {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 18px;
                        color: #656565;
                    }

                    .required:before {
                        content: "* ";
                        color: red;
                    }
                }
            }
        }
    }
}