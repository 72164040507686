.booking-details {
    margin-top: 9.11px;
    display: flex;
    flex: 1;
    flex-direction: column;

    .header {
        display: flex;

        p:nth-child(1) {
            white-space: nowrap;
            font-style: normal;
            font-weight: 700;
            font-size: 28px;
            line-height: 38px;
            color: #1E1E1E;
        }

        p:nth-child(2) {
            margin-top: 10px;
            white-space: nowrap;
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 22px;
            color: #000000;
        }

        button {
            height: 38px;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            color: #FFFFFF;
            border: none;
            border-radius: 10px;
            background-color: #FC7921;
            padding: 0px 38px 0px 38px;
            white-space: nowrap;
        }

        .invoice-button {
            margin-left: auto;
            margin-right: 20px;
        }

        .booking-button {
            margin-left: auto;
        }

        .booking-button-cancel {
            background-color: #dbdbdb;
        }
    }

    .content {
        margin-top: 24.82px;
        margin-bottom: 32.65px;
        display: flex;
        height: 100%;
        width: 100%;

        .details-container {
            background-color: white;
            height: 100%;
            flex: 1;
            border-radius: 15px;
            display: flex;
            flex-direction: row;

            .more {
                flex: 0.3;
                display: block;
                float: right;
                margin-top: 30px;
                margin-right: 25px;

                .moreButtonContainer {
                    // float: right;
                    height: 18px;
                    margin-bottom: 10px;
                    padding-right: 13px;

                    .more-button {
                        width: 20px;
                        background: none;
                        border: none;
                        float: right;
                    }
                }

                .moreAction {
                    width: 135px;
                    margin-left: auto;
                    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

                    button {
                        background: none;
                        border: none;
                        width: 100%;
                        text-align: left;
                        height: 58px;
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 18px;
                        color: #313131;
                        padding: 10px 22px 10px;
                        align-items: center;
                        display: flex;

                        .more-icon {
                            margin-right: 5px;
                        }
                    }

                    button:hover {
                        color: rgba(239, 70, 36, 1);
                        filter: invert(33%) sepia(58%) saturate(2216%) hue-rotate(346deg) brightness(95%) contrast(96%);
                    }
                }
            }

            .details {
                height: 100%;
                flex: 1;

                .details-title {
                    margin-left: 25.06px;
                    margin-right: 55.91px;
                    margin-top: 23.06px;
                    color: #000;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                }

                .details-breakdown>.details-title {
                    margin-top: 51.36px;
                }

                .details-form {
                    .details-layout {
                        display: flex;
                        margin-left: 25.06px;
                        margin-right: 25.06px;
                        margin-bottom: 31.36px;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 18px;
                        color: #656565;
                        overflow: hidden;

                        .details-layout-item:nth-child(1) {
                            flex: 0.35;
                        }

                        .details-layout-item:nth-child(2) {
                            flex: 1;
                            white-space: pre-line;
                        }

                        .details-layout-item>p>a {
                            text-decoration: underline;
                            color: #FC7921;
                        }

                        .details-layout-refresh {
                            display: flex;
                            align-items: center;

                            .details-layout-refresh-icon {
                                width: 18px;
                                height: 18px;
                                margin-left: 15px;
                                cursor: pointer;

                                path {
                                    fill: #FC7921;
                                }
                            }
                        }
                    }
                }

                .details-layout:nth-child(1) {
                    margin-top: 32.11px;
                }
            }
        }

        .details-supporting {
            background-color: white;
            flex: 0.51;
            overflow: hidden;
            margin-left: 25.49px;
            border-radius: 15px;
            padding-top: 23.06px;
            padding-left: 25.06px;
            padding-right: 15.9px;

            .details-supporting-container {
                display: flex;
                flex-direction: column;

                .details-supporting-label {
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    color: #000;
                }

                .details-supporting-label2 {
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    color: #000;
                    margin-top: 20px;
                }

                .details-supporting-form {
                    .details-layout {
                        display: flex;
                        margin-bottom: 31.36px;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 18px;
                        color: #656565;
                        overflow: hidden;

                        .details-layout-item:nth-child(1) {
                            flex: 0.5;
                        }

                        .details-layout-item:nth-child(2) {
                            flex: 1;
                            white-space: pre-line;
                        }

                        .details-layout-item>p>a {
                            text-decoration: underline;
                            color: #FC7921;
                        }
                    }
                }

                .details-layout:nth-child(1) {
                    margin-top: 32.11px;
                }
            }

            .details-supporting-container:not(:first-child) {
                margin-top: 16px;
            }
        }
    }

    .session {
        .header {
            display: flex;
            align-items: center;
        }

        .table-container {
            margin-top: 20.17px;
        }
    }
}