.custom-table-dialog {
    background-color: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;

    .backgroundButton {
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: transparent;
        cursor: auto;
        border: none;
    }

    .container {
        margin-top: 40px;
        background-color: #F5F5F5;
        width: 85%;
        max-height: 85%;
        margin: 0 auto;
        position: relative;
        border-radius: 20px;
        overflow: hidden;

        .container2 {
            height: 100%;
            width: 100%;
            overflow: auto;
        }

        .custom-table-header {
            display: flex;
            align-items: center;
            margin-top: 32px;
            margin-left: 32px;
            margin-right: 32px;

            p {
                white-space: nowrap;
                font-style: normal;
                font-weight: 700;
                font-size: 28px;
                line-height: 38px;
                color: #1E1E1E;
                align-self: center;
            }

            .search-bar {
                .search-bar-container {
                    margin-left: 55.5px;
                    margin-top: 0;
                }
            }
        }

        .table-container {
            margin-top: 20.17px;
            margin-left: 32px;
            margin-right: 32px;
        }

        .buttonContainer {
            display: flex;
            gap: 10px;
            justify-content: space-between;
            margin-top: 40px;
            margin-left: 32px;
            margin-right: 32px;
            margin-bottom: 32px;

            button {
                position: relative;
                flex-wrap: wrap;
                min-width: 130px;
                height: 40px;
                background: none;
                border-radius: 10px;
                align-items: center;
                align-content: center;
                border: none;
                display: flex;
                gap: 10px;
                justify-content: center;
                color: #1E1E1E;
            }

            button:nth-child(1) {
                justify-content: left;
            }

            button:nth-child(2) {
                path {
                    stroke: white !IMPORTANT;
                }
            }
        }
    }
}