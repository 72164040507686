.login-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .login-logo {
        width: 203px;
        margin-bottom: 25px;
    }

    .input-container {
        display: flex;
        flex-direction: column;
        width: 350px;
        margin-top: 10px;

        label {
            white-space: nowrap;
        }

        .details-div-layout-input[type="text"],
        .details-div-layout-input[type="password"] {
            border: 0.783063px solid #C5C5C5;
            border-radius: 10px;
            padding: 8px 12px;
            margin-top: 5px;
        }
    }

    .button-container {
        justify-content: center;
        align-items: center;
        display: flex;
    }

    button {
        margin-top: 20px;
        cursor: pointer;
        font-size: 15px;
        width: 100%;
        background: #FC7921;
        border: 0px;
        color: #fff;
        padding: 10px 20px;
        border-radius: 10px;
    }
}