.redemption-voucher-details {
    margin-top: 9.11px;
    display: flex;
    flex: 1;
    flex-direction: column;

    .header {
        display: flex;

        p:nth-child(1) {
            white-space: nowrap;
            font-style: normal;
            font-weight: 700;
            font-size: 28px;
            line-height: 38px;
            color: #1E1E1E;
        }

        p:nth-child(2) {
            margin-top: 10px;
            white-space: nowrap;
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 22px;
            color: #000000;
        }

        button {
            margin-left: auto;
            height: 38px;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            color: #FFFFFF;
            border: none;
            border-radius: 10px;
            background-color: #FC7921;
            padding: 0px 38px 0px 38px;
            white-space: nowrap;
        }
    }

    .content {
        margin-top: 24.82px;
        margin-bottom: 32.65px;
        display: flex;
        height: 100%;
        width: 100%;

        .details {
            background-color: white;
            height: 100%;
            flex: 1;
            border-radius: 15px;

            .details-layout {
                display: flex;
                margin-left: 25.06px;
                margin-right: 25.06px;
                margin-bottom: 31.36px;
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 18px;
                color: #656565;
                overflow: hidden;
            }

            .details-layout>p:nth-child(1) {
                flex: 0.35;
                white-space: nowrap;
            }

            .details-layout>p:nth-child(2) {
                flex: 1;
                margin-left: 10px;
            }

            .details-layout-add-edit-label {
                margin-top: 5px;
            }

            .details-layout-add-edit {
                flex: 1;
                margin-left: 10px;
                display: flex;
                align-items: center;

                .details-layout-add-icon {
                    width: 18px;
                    height: 18px;
                    margin-left: 20px;
                    cursor: pointer;
                    filter: invert(56%) sepia(68%) saturate(1878%) hue-rotate(343deg) brightness(97%) contrast(103%);
                }

                .details-layout-minus-icon {
                    width: 30px;
                    height: 30px;
                    margin-left: 15px;
                    cursor: pointer;
                    filter: invert(56%) sepia(68%) saturate(1878%) hue-rotate(343deg) brightness(97%) contrast(103%);
                }
            }

            div:nth-child(1) {
                margin-top: 32.11px;
            }
        }

        .details-html {
            display: flex;
            flex-direction: column;
            flex: 1;
            margin-left: 10px;

            a {
                text-decoration: underline;
                color: #FC7921;
            }

            ul,
            ol {
                padding-inline-start: 20px;
            }

            ul>li {
                margin-left: 3px;
            }
        }

        .details-image {
            background-color: white;
            height: 100%;
            flex: 0.65;
            overflow: hidden;
            margin-left: 25.49px;
            border-radius: 15px;

            .logo {
                display: flex;
                flex-direction: column;
                margin-left: 30px;
                margin-right: 30px;
                margin-top: 25.56px;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                color: #656565;

                .upload {
                    display: flex;
                    flex-direction: column;
                    border-radius: 15px;
                    border-color: #C5C5C5;
                    border-width: 2px;
                    border-style: dashed;
                    margin-top: 23px;
                    padding: 23px 35.5px 19.23px 35.5px;
                    align-items: center;
                    margin-left: 27px;
                    margin-right: 27px;
                    overflow: hidden;

                    .upload-logo {
                        width: 140px;
                        height: 140px;
                    }

                    p {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 10px;
                        line-height: 14px;
                        text-align: center;
                        color: #5D5D5F;
                        opacity: 0.6;
                    }
                }
            }
        }
    }
}