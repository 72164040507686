.promotions {
    margin-top: 9.11px;
    display: flex;
    flex: 1;
    flex-direction: column;

    .header {
        display: flex;

        p:nth-child(1) {
            white-space: nowrap;
            font-style: normal;
            font-weight: 700;
            font-size: 28px;
            line-height: 38px;
            color: #1E1E1E;
        }

        p:nth-child(2) {
            margin-top: 10px;
            white-space: nowrap;
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 22px;
            color: #000000;
        }

        button {
            margin-left: auto;
            height: 38px;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            color: #FFFFFF;
            border: none;
            border-radius: 10px;
            background-color: #FC7921;
            padding: 0px 38px 0px 38px;
            white-space: nowrap;
        }
    }

    .content {
        margin-top: 24.82px;
        margin-bottom: 32.65px;
        display: flex;
        height: 100%;
        width: 100%;

        .details {
            background-color: white;
            flex: 1;
            border-radius: 15px;
            padding-left: 25.06px;
            padding-right: 55.91px;
            padding-top: 23.06px;

            .details-layout {
                display: flex;
                margin-top: 30px;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;
                color: #656565;
                overflow: hidden;
                flex-direction: column;

                .details-layout-content {
                    display: flex;
                    gap: 15px;
                    align-items: center;
                }

                .details-layout-content:nth-child(2) {
                    margin-top: 15px;

                    p:nth-child(1) {
                        visibility: hidden;
                    }
                }
            }

            label {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;
                color: #656565;
            }

            .required:before {
                content: "* ";
                color: red;
            }
        }
    }
}